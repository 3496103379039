
:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  font-weight: 500;
  color: #8b8b8b;
  text-decoration: none;
}


textarea:focus, input:focus{
  outline: none;
}

body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;

}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

.card {
  padding: 2em;
}

#root {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: #fc4c0a;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #fc4c0a;
  }
  button {
    background-color: #f9f9f9;
  }
}


h1 {
  color: #fc4c0a;
}

.github-logo{
  position: fixed; /* Positioning relative to the viewport */
  top: 0; /* 0px from the bottom */
  right: 0; 
}

.github-icon {
  height: 4vh;
  margin-right: 0.5em;
margin-top: 0.5em;


}

.buy-me-coffee {
  /* height: 5vh;
  width: 5vw; */
  position: fixed; /* Positioning relative to the viewport */
  bottom: 0; /* 0px from the bottom */
  right: 0; /* 0px from the right */
}

.buy-me-coffee-black {
  height: 6vh;
}