.footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    margin-top: 2em;
}

a {
    color: white;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="footer"] {
        margin-bottom: 4vh;
        /* height: 30vh; */
    }
}